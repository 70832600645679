import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { useMediaQuery } from '@cocoonspace/shared/hooks/use-media-query.hook'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { CommandMenu } from '~/components/command-menu'
import { PageLoader } from '~/components/page-loader'
import { Sidebar } from '~/components/sidebar'
import { useUserPrefsStore } from '~/stores/user-prefs.store'

export const PrivateLayout = ({ children }: { children: any }) => {
	const router = useRouter()
	const { isLoading, isAuth } = useAuth()
	const isDesktop = useMediaQuery('(min-width: 768px)')

	const initThemeAndMode = useUserPrefsStore.use.initThemeAndMode()
	const isSidebarVisible = useUserPrefsStore.use.isSidebarVisible()

	useEffect(() => {
		initThemeAndMode()
	}, [])

	useEffect(() => {
		if (!isLoading && !isAuth) {
			router.push('/login')
		}
	}, [isAuth, isLoading, router])

	if (isLoading) return <PageLoader />

	if (!isLoading && isAuth) {
		return (
			<div className='flex h-[100dvh] flex-row'>
				{isDesktop && isSidebarVisible && <Sidebar />}

				<div
					key={router.route}
					className='flex flex-1 flex-col overflow-y-auto overflow-x-hidden'
				>
					{children}
				</div>

				<CommandMenu />
			</div>
		)
	}

	return null
}
